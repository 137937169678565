/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main-header {
  text-align: center;
}
.background {
  background: white;
  padding: 10px 10px 50px;
}
.mobile .background {
  padding: 12px 16px;
}
.page-action-right {
  float: right;
  margin-bottom: 8px;
}
