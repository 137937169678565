.claim-subsection {
	margin-left : 64px;
	margin-bottom : 32px;
	.mobile &, .tablet & {
		margin-left : 16px;
	}
}
.claim-subsection-full {
	margin-bottom : 32px;
}
.claim-limit-width {
	max-width : 800px;
}
.claim-row {
	margin-bottom : 16px;
	> .title {
		color : @secondary-color;
		font-family: @font-family-title;
		font-size : 1.1em;
		margin-bottom : 8px;
		text-decoration: underline;
	}

	label {
		color : @info-color;
		.mobile & {
			margin-bottom : 4px;
		}
	}

	.claim-input {
		padding-top : 4px;
		padding-bottom : 8px;
	}

	.help {
		color : @text-muted;
		margin-top : 2px;
		font-size : 0.95em;
	}

	&.claim-row-sameline {
		> label {
			margin-right :8px;
			text-align : right;
			width : 140px;
			display : inline-block;
			&::after {
				content : ' : '
			}
		}

		.claim-input , .claim-value {
			display : inline-block;
		}
	}

}
