/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.claim-subsection {
  margin-left: 64px;
  margin-bottom: 32px;
}
.mobile .claim-subsection,
.tablet .claim-subsection {
  margin-left: 16px;
}
.claim-subsection-full {
  margin-bottom: 32px;
}
.claim-limit-width {
  max-width: 800px;
}
.claim-row {
  margin-bottom: 16px;
}
.claim-row > .title {
  color: #D22027;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  margin-bottom: 8px;
  text-decoration: underline;
}
.claim-row label {
  color: #1890ff;
}
.mobile .claim-row label {
  margin-bottom: 4px;
}
.claim-row .claim-input {
  padding-top: 4px;
  padding-bottom: 8px;
}
.claim-row .help {
  color: #8c8c8c;
  margin-top: 2px;
  font-size: 0.95em;
}
.claim-row.claim-row-sameline > label {
  margin-right: 8px;
  text-align: right;
  width: 140px;
  display: inline-block;
}
.claim-row.claim-row-sameline > label::after {
  content: ' : ';
}
.claim-row.claim-row-sameline .claim-input,
.claim-row.claim-row-sameline .claim-value {
  display: inline-block;
}
.part-display-select {
  display: block;
  margin-bottom: 8px;
  width: 200px;
}
